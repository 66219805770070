import { createApp } from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import App from './App.vue'
import router from './router'

createApp(App)
  .use(VueReCaptcha, {
    siteKey: '6LdWHMQaAAAAAHBsP9OTSspsyRAszECW0D8wJl9n',
  })
  .use(router)
  .mount('#app')
