<template>
  <div class="resultWrapper basicWrapper">
    <div class="infoWrapper col-xl-5 col-lg-5 col-md-6">
      <div class="resultTitle">
        <div class="returnBtn" @click="$emit('onSubmit', false)">
          <svg>
            <use href="#arrow" />
          </svg>
        </div>
        <h2>執行結果</h2>
        <div class="popupBtn btn btn-sm" @click="isImageShow = true">圖片</div>
      </div>

      <div class="content">
        <div class="row">
          <div class="title">
            <div>NSFW 偵測</div>
            <div class="infoIcon" onclick="">
              <svg><use href="#info" /></svg>
              <!-- TODO: change hint text -->
              <div class="infoBubble">獲得 0-1 等分數</div>
            </div>
          </div>

          <div class="scaleWrapper">
            <div class="number">0</div>
            <div id="scale">
              <div id="point" :style="{ left: pointPosition }">
                <div class="bubble">{{ responseData.nsfw }}</div>
              </div>
            </div>
            <div class="number">1</div>
          </div>
        </div>
        <div class="blocks">
          <div class="row d-flex runtimeWrapper">
            <div class="block">
              <!-- TODO: change runtime title -->
              <div class="title">執行時間(短)</div>
              <div>
                <p>
                  <span>{{ responseData.runtime1 }}</span> ms
                </p>
              </div>
            </div>
            <div class="block">
              <!-- TODO: change runtime title -->
              <div class="title">執行時間(長)</div>
              <div>
                <p>
                  <span>{{ responseData.runtime2 }}</span> ms
                </p>
              </div>
            </div>
          </div>
          <div class="row d-flex imageInfoWrapper">
            <div class="block">
              <div class="title">原圖片</div>
              <div>
                <p>{{ scale(responseData.originalScale) }}</p>
                <p>{{ sizeConvert(responseData.originalSize) }} KB</p>
              </div>
            </div>
            <div class="block">
              <div class="title">Resaizer</div>
              <div>
                <p>{{ scale(responseData.resaizerScale) }}</p>
                <p>
                  {{ sizeConvert(responseData.resaizerSize) }} KB
                  <span>(-{{ lessPercent }}%)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="imgWrapper_1" class="imgWrapper col-xl-7 col-lg-7 col-md-6">
      <div class="imgResult">
        <a :href="imageUrl" target="_blank">
          <img :src="imageSrc" alt="generate_image" />
          <div class="magnifier">
            <svg><use href="#magnifier" /></svg>
          </div>
        </a>
      </div>
    </div>
    <div
      id="imgWrapper_2"
      class="imgWrapper"
      :class="isImageShow ? 'showImg' : 'hideImg'"
      @click="isImageShow = false"
    >
      <div class="imgResult">
        <a :href="imageUrl" target="_blank" @click.stop>
          <img :src="imageSrc" alt="generate_image" />
          <div class="magnifier">
            <svg><use href="#magnifier" /></svg>
          </div>
        </a>
        <div class="closeBtn btn-secondary">
          <svg width="30" height="30" viewBox="0 0 30 30">
            <line x1="0" y1="0" x2="30" y2="30" />
            <line x1="0" y1="30" x2="30" y2="0" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
  emits: ['onSubmit'],
  props: {
    isSubmit: Boolean,
    imageSrc: String,
    imageUrl: String,
    responseData: Object,
  },

  setup(props) {
    const isImageShow = ref(false)
    const pointPosition = computed(
      () => `calc( ${props.responseData.nsfw} * 100% )`
    )

    const scale = (data = '0x0') => {
      const arr = data.split('x')
      return `${arr[0]} × ${arr[1]}`
    }

    const sizeConvert = (size) => Math.round(size / 1024)

    const lessPercent = computed(() => {
      return Math.round(
        (1 -
          props.responseData.resaizerSize / props.responseData.originalSize) *
          100
      )
    })

    return { isImageShow, pointPosition, scale, sizeConvert, lessPercent }
  },
})
</script>

<style scoped lang="scss" src="@/styles/components/_demoResult.scss"></style>
