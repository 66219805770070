
import { defineComponent, ref } from 'vue'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
  props: {
    isLoading: Boolean,
    isSubmit: Boolean,
  },

  setup(props, { emit }) {
    const recaptcha = useReCaptcha()
    if (!recaptcha) {
      return
    }
    const { recaptchaLoaded, executeRecaptcha } = recaptcha

    const getRecaptcha = async () => {
      await recaptchaLoaded()
      return await executeRecaptcha('login')
    }

    const isWidth = ref(true)
    const link = ref('')
    const imgScale = ref(300)
    const setWidth = (x: boolean) => {
      isWidth.value = x
    }

    const sandRequest = async () => {
      const token = await getRecaptcha()
      if (link.value) {
        const imageUrl =
          link.value.startsWith('https://') || link.value.startsWith('http://')
            ? link.value
            : 'http://' + link.value

        if (imgScale.value >= 1 && imgScale.value <= 1000) {
          const maxSide = isWidth.value ? 'w' : 'h'
          const reqData = {
            url: imageUrl,
            size: imgScale.value,
            side: maxSide,
            token: token,
          }

          // console.log(reqData)
          emit('onSubmit', true, reqData)
        }
      }
    }

    return { isWidth, setWidth, link, imgScale, sandRequest }
  },
})
