<template>
  <div class="wrapper sliderOff">
    <div class="feature" v-for="{ text, title, icon } in features" :key="title">
      <div class="icon"><i class="demo-icon" :class="`icon-${icon}`" /></div>
      <h3>{{ title }}</h3>
      <p>{{ text }}</p>
    </div>
  </div>
  <div ref="slider" class="wrapper sliderOn">
    <div
      class="feature keen-slider__slide"
      v-for="{ text, title, icon } in features"
      :key="title"
    >
      <div class="icon"><i class="demo-icon" :class="`icon-${icon}`" /></div>
      <div class="textWrap">
        <h3>{{ title }}</h3>
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
  <div v-if="slider" class="dots">
    <button
      v-for="(slide, idx) in dotHelper"
      :class="{ dot: true, active: current === idx }"
      :key="idx"
    ></button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import KeenSlider from 'keen-slider'

export default defineComponent({
  name: 'Features',
  data() {
    return {
      features: [
        {
          icon: 'fast',
          title: '極速縮圖',
          text: '使用自家演算法，每秒鐘都能運算大量圖片及提供縮圖服務',
        },
        {
          icon: 'detect',
          title: 'NSFW 偵測',
          text:
            '使用人工智能偵測含有成人內容的圖片，並在 HTTP 頭欄位回傳分數，幫助您保護 UGC 內容',
        },
        {
          icon: 'gif',
          title: '支援動態圖片',
          text:
            '所有 GIF 或動態 WEBP 格式都已支援，同時亦能快速為您提供運算服務',
        },
        {
          icon: 'cloud',
          title: '圖片 CDN',
          text:
            '我們使用 Cloudfare CDN 緩存已處理的圖片，為您的網站提高載入速度',
        },
      ],
      current: 1,
      slider: null,
      pause: false,
      interval: null,
    }
  },
  computed: {
    dotHelper() {
      return this.slider ? [...Array(this.slider.details().size).keys()] : []
    },
  },
  methods: {
    setPause(active) {
      this.pause = active
      this.setInterval()
    },
    resetInterval() {
      clearInterval(this.interval)
    },
    setInterval() {
      this.resetInterval()
      this.interval = setInterval(() => {
        if (!this.pause) {
          this.slider.next()
        }
      }, 2000)
    },
  },
  mounted() {
    this.slider = new KeenSlider(this.$refs.slider, {
      initial: this.current,
      loop: true,
      slideChanged: (s) => {
        this.current = s.details().relativeSlide
      },
      duration: 1000,
      dragStart: () => {
        this.setPause(true)
      },
      dragEnd: () => {
        this.setPause(false)
      },
    })
    this.setInterval()
  },
  beforeUnmount() {
    if (this.slider) this.slider.destroy()
  },
})
</script>

<style lang="scss" src="@/styles/components/_feature.scss"></style>
