<template>
  <div class="demoWrapper basicWrapper col-xl-6 col-lg-6 col-md-10 col-sm-12">
    <h2>體驗 Res<span>ai</span>zer</h2>
    <div class="content">
      <form @submit.prevent="onSubmit">
        <div class="row">
          <div class="title">
            <label>圖片網址</label>
            <div class="infoIcon" onclick="">
              <svg><use href="#info" /></svg>
              <div class="infoBubble">
                <!-- TODO: change hint text -->
                可輸入gif、WEBP、png、jpg等格式之圖片連結
              </div>
            </div>
          </div>

          <input
            id="imgLink"
            type="text"
            placeholder="https://codezero.tw/design.png"
            v-model="link"
            required
          />
        </div>
        <div class="row">
          <div class="title">
            <label>縮放尺寸 (px) </label>
            <div class="infoIcon" onclick="">
              <svg><use href="#info" /></svg>
              <div class="infoBubble">
                <!-- TODO: change hint text -->
                輸入圖片的最長邊<br />(1-1000)並選擇寬度／高度
              </div>
            </div>
          </div>

          <div class="imgScale">
            <input
              id="imgScale"
              type="number"
              min="1"
              max="1000"
              v-model="imgScale"
              required
            />
            <div class="scaleOption">
              <div :class="{ active: isWidth }" @click="setWidth(true)">
                寬度
              </div>
              <div>|</div>
              <div :class="{ active: !isWidth }" @click="setWidth(false)">
                高度
              </div>
            </div>
          </div>
        </div>
        <div class="submitWrapper">
          <button @click="!isLoading && sandRequest()" class="btn btn-sm">
            {{ isLoading ? '載入中' : '送出' }}
          </button>
        </div>
        <div class="loading" :class="{ hideLoading: !isLoading }">
          <div></div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
  props: {
    isLoading: Boolean,
    isSubmit: Boolean,
  },

  setup(props, { emit }) {
    const recaptcha = useReCaptcha()
    if (!recaptcha) {
      return
    }
    const { recaptchaLoaded, executeRecaptcha } = recaptcha

    const getRecaptcha = async () => {
      await recaptchaLoaded()
      return await executeRecaptcha('login')
    }

    const isWidth = ref(true)
    const link = ref('')
    const imgScale = ref(300)
    const setWidth = (x: boolean) => {
      isWidth.value = x
    }

    const sandRequest = async () => {
      const token = await getRecaptcha()
      if (link.value) {
        const imageUrl =
          link.value.startsWith('https://') || link.value.startsWith('http://')
            ? link.value
            : 'http://' + link.value

        if (imgScale.value >= 1 && imgScale.value <= 1000) {
          const maxSide = isWidth.value ? 'w' : 'h'
          const reqData = {
            url: imageUrl,
            size: imgScale.value,
            side: maxSide,
            token: token,
          }

          // console.log(reqData)
          emit('onSubmit', true, reqData)
        }
      }
    }

    return { isWidth, setWidth, link, imgScale, sandRequest }
  },
})
</script>

<style scoped lang="scss" src="@/styles/components/_demoForm.scss" />
