
import {
  defineComponent,
  ref,
  computed,
  provide,
  Component,
  defineAsyncComponent,
} from 'vue'

interface LayoutMap {
  [key: string]: Component
}

const layoutMap: LayoutMap = {
  default: defineAsyncComponent(() => import('@/layouts/Defaults.vue')),
}

export default defineComponent({
  name: 'App',
  setup() {
    const view = ref('default')
    const layout = computed(() => layoutMap[view.value])
    provide('setLayout', (_view: string) => {
      view.value = _view
    })
    return { layout }
  },
})
