<template>
  <main>
    <div id="mainImage" class="outter">
      <div class="inner">
        <div class="col-xl-6 col-lg-6 col-md-7 col-sm-12 mainImageText">
          <h2>以 <span>AI</span> 處理影像</h2>
          <p>
            Resaizer 不僅提供圖片代理服務，也能幫助您的網站極速縮圖，偵測 NSFW
            內容及提供緩存服務
          </p>
        </div>
        <div
          class="col-xl-6 col-lg-6 col-md-5 col-sm-12 centerImg mainImagePic"
        >
          <MainImageV2 />
        </div>
      </div>
    </div>

    <div id="features" class="outter">
      <div class="inner">
        <Features />
      </div>
    </div>

    <div id="linkDemo" class="outter">
      <div class="inner">
        <div class="col-xl-5 col-lg-5 centerImg">
          <img src="@/static/resaizer_kv_w.png" alt="main-image" />
        </div>
        <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
          <h2>簡單易用</h2>
          <p>
            您只需要把圖片連結換成代理連結，即可享受 Resaizer 的圖像處理服務
          </p>
          <div class="linkInput">
            <p>
              /image?url=<span>http://example.com/hello.png</span>&size=100
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="contact" class="outter">
      <div id="particles-js"></div>
      <div class="mask"></div>
      <div class="inner">
        <h2>立即體驗 Res<span>ai</span>zer</h2>

        <div class="btnBox">
          <router-link custom to="/demo" v-slot="{ navigate }">
            <button class="btn btn-lg" @click="navigate">線上試用</button>
          </router-link>
          <a href="mailto:resaizer@codezero.tw" class="btn btn-secondary btn-lg"
            >業務接洽</a
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import Features from '@/components/Features.vue'
import MainImageV2 from '@/components/MainImage2.vue'
import 'particles.js'
import particleConfig from '@/static/particles_config.json'

export default defineComponent({
  name: 'Home',
  components: {
    Features,
    MainImageV2,
  },
  setup() {
    onMounted(() => {
      window.particlesJS('particles-js', particleConfig)
    })
  },
})
</script>

<style lang="scss" src="@/styles/pages/_home.scss" />
