<template>
  <svg
    class="image"
    cache-id="d9947982cddf4c55962b5fa88ae77f69"
    id="eWYhCtNA7Py1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 500 500"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
  >
    <path
      id="eWYhCtNA7Py2"
      d="M409.400000,140.500000L409.400000,359.500000C409.400000,365,404.900000,369.500000,399.400000,369.500000L100.600000,369.500000C95.100000,369.500000,90.600000,365,90.600000,359.500000L90.600000,140.500000C90.600000,135,95.100000,130.500000,100.600000,130.500000L399.300000,130.500000C404.900000,130.500000,409.400000,135,409.400000,140.500000ZM110.700000,156.200000L110.700000,299.200000L187.400000,244.100000C190.600000,241.800000,194.900000,241.600000,198.300000,243.600000L283.300000,293.500000L340,257.200000C343.200000,255.100000,347.200000,255.100000,350.500000,257L389.500000,280.300000L389.500000,156.200000C389.500000,153,386.900000,150.500000,383.800000,150.500000L116.300000,150.500000C113.100000,150.500000,110.600000,153,110.700000,156.200000ZM293.700000,208.800000C293.700000,193.612170,306.012170,181.300000,321.200000,181.300000C336.387830,181.300000,348.700000,193.612170,348.700000,208.800000C348.700000,223.987830,336.387830,236.300000,321.200000,236.300000C306.012170,236.300000,293.700000,223.987830,293.700000,208.800000Z"
      transform="matrix(0.95000000000000 0 0 0.95000000000000 12.50000000000000 12.50000000000000)"
      opacity="0.5"
      fill="hsl(120, 61%, 68%)"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="eWYhCtNA7Py3"
      d="M369.500000,100.600000L369.500000,399.300000C369.500000,404.800000,365,409.300000,359.500000,409.300000L140.500000,409.300000C135,409.300000,130.500000,404.800000,130.500000,399.300000L130.500000,100.600000C130.500000,95.100000,135,90.600000,140.500000,90.600000L359.500000,90.600000C365,90.600000,369.500000,95.100000,369.500000,100.600000ZM150.600000,116.300000L150.600000,288.800000L205.300000,242.300000C209.300000,239,215.200000,239.200000,218.900000,242.900000L273.600000,297.700000L308.400000,271.300000C311.900000,268.700000,316.600000,268.600000,320.200000,271.100000L349.600000,291.900000L349.600000,116.300000C349.600000,113.100000,347,110.600000,343.900000,110.600000L156.200000,110.600000C153,110.600000,150.500000,113.100000,150.600000,116.300000ZM265.700000,179C265.700000,163.812170,278.012170,151.500000,293.200000,151.500000C308.387830,151.500000,320.700000,163.812170,320.700000,179C320.700000,194.187830,308.387830,206.500000,293.200000,206.500000C278.012170,206.500000,265.700000,194.187830,265.700000,179Z"
      transform="matrix(0.25000000000000 0 0 0.25000000000000 187.50000000000000 147.51249999999999)"
      opacity="0"
      fill="hsl(120, 61%, 68%)"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="eWYhCtNA7Py4"
      d="M235.400000,305.400000L210.400000,305.400000C202.100000,305.400000,195.400000,298.700000,195.400000,290.400000L195.400000,265.400000C195.400000,259.900000,199.900000,255.400000,205.400000,255.400000C210.900000,255.400000,215.400000,259.900000,215.400000,265.400000L215.400000,285.400000L235.400000,285.400000C240.900000,285.400000,245.400000,289.900000,245.400000,295.400000C245.400000,300.900000,240.900000,305.400000,235.400000,305.400000Z"
      transform="matrix(1 0 0 1 -146.82999999648436 108.12500000351559)"
      opacity="0"
      fill="hsl(120, 61%, 68%)"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="eWYhCtNA7Py5"
      d="M295.400000,245.400000C289.900000,245.400000,285.400000,240.900000,285.400000,235.400000L285.400000,215.400000L265.400000,215.400000C259.900000,215.400000,255.400000,210.900000,255.400000,205.400000C255.400000,199.900000,259.900000,195.400000,265.400000,195.400000L290.400000,195.400000C298.700000,195.400000,305.400000,202.100000,305.400000,210.400000L305.400000,235.400000C305.400000,241,300.900000,245.400000,295.400000,245.400000Z"
      transform="matrix(1 0 0 1 146.02999999999997 -108.92500000000000)"
      opacity="0"
      fill="hsl(120, 61%, 68%)"
      stroke="none"
      stroke-width="1"
    />
  </svg>
</template>

<script>
/* eslint-disable */
export default {
  mounted() {
    !(function (t, n) {
      'object' == typeof exports && 'undefined' != typeof module
        ? (module.exports = n())
        : 'function' == typeof define && define.amd
        ? define(n)
        : ((t =
            'undefined' != typeof globalThis
              ? globalThis
              : t || self).__SVGATOR_PLAYER__ = n())
    })(this, function () {
      'use strict'
      function t(n) {
        return (t =
          'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
            ? function (t) {
                return typeof t
              }
            : function (t) {
                return t &&
                  'function' == typeof Symbol &&
                  t.constructor === Symbol &&
                  t !== Symbol.prototype
                  ? 'symbol'
                  : typeof t
              })(n)
      }
      function n(t, n) {
        if (!(t instanceof n))
          throw new TypeError('Cannot call a class as a function')
      }
      function r(t, n) {
        for (var r = 0; r < n.length; r++) {
          var e = n[r]
          ;(e.enumerable = e.enumerable || !1),
            (e.configurable = !0),
            'value' in e && (e.writable = !0),
            Object.defineProperty(t, e.key, e)
        }
      }
      function e(t, n, e) {
        return n && r(t.prototype, n), e && r(t, e), t
      }
      function i(t) {
        return (i = Object.setPrototypeOf
          ? Object.getPrototypeOf
          : function (t) {
              return t.__proto__ || Object.getPrototypeOf(t)
            })(t)
      }
      function o(t, n) {
        return (o =
          Object.setPrototypeOf ||
          function (t, n) {
            return (t.__proto__ = n), t
          })(t, n)
      }
      function u(t, n) {
        return !n || ('object' != typeof n && 'function' != typeof n)
          ? (function (t) {
              if (void 0 === t)
                throw new ReferenceError(
                  "this hasn't been initialised - super() hasn't been called"
                )
              return t
            })(t)
          : n
      }
      function a(t) {
        var n = (function () {
          if ('undefined' == typeof Reflect || !Reflect.construct) return !1
          if (Reflect.construct.sham) return !1
          if ('function' == typeof Proxy) return !0
          try {
            return (
              Boolean.prototype.valueOf.call(
                Reflect.construct(Boolean, [], function () {})
              ),
              !0
            )
          } catch (t) {
            return !1
          }
        })()
        return function () {
          var r,
            e = i(t)
          if (n) {
            var o = i(this).constructor
            r = Reflect.construct(e, arguments, o)
          } else r = e.apply(this, arguments)
          return u(this, r)
        }
      }
      function l(t, n, r) {
        return (l =
          'undefined' != typeof Reflect && Reflect.get
            ? Reflect.get
            : function (t, n, r) {
                var e = (function (t, n) {
                  for (
                    ;
                    !Object.prototype.hasOwnProperty.call(t, n) &&
                    null !== (t = i(t));

                  );
                  return t
                })(t, n)
                if (e) {
                  var o = Object.getOwnPropertyDescriptor(e, n)
                  return o.get ? o.get.call(r) : o.value
                }
              })(t, n, r || t)
      }
      var f = Math.abs
      function s(t) {
        return t
      }
      function c(t, n, r) {
        var e = 1 - r
        return 3 * r * e * (t * e + n * r) + r * r * r
      }
      function h() {
        var t =
            arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0,
          n =
            arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
          r =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1,
          e = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 1
        return t < 0 || t > 1 || r < 0 || r > 1
          ? null
          : f(t - n) <= 1e-5 && f(r - e) <= 1e-5
          ? s
          : function (i) {
              if (i <= 0)
                return t > 0 ? (i * n) / t : 0 === n && r > 0 ? (i * e) / r : 0
              if (i >= 1)
                return r < 1
                  ? 1 + ((i - 1) * (e - 1)) / (r - 1)
                  : 1 === r && t < 1
                  ? 1 + ((i - 1) * (n - 1)) / (t - 1)
                  : 1
              for (var o, u = 0, a = 1; u < a; ) {
                var l = c(t, r, (o = (u + a) / 2))
                if (f(i - l) < 1e-5) break
                l < i ? (u = o) : (a = o)
              }
              return c(n, e, o)
            }
      }
      function v() {
        return 1
      }
      function y(t) {
        return 1 === t ? 1 : 0
      }
      function d() {
        var t =
            arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1,
          n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0
        if (1 === t) {
          if (0 === n) return y
          if (1 === n) return v
        }
        var r = 1 / t
        return function (t) {
          return t >= 1 ? 1 : (t += n * r) - (t % r)
        }
      }
      function g(t) {
        var n =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 2
        if (Number.isInteger(t)) return t
        var r = Math.pow(10, n)
        return Math.round(t * r) / r
      }
      var p = Math.PI / 180
      function m(t, n, r) {
        return t >= 0.5 ? r : n
      }
      function b(t, n, r) {
        return 0 === t || n === r ? n : t * (r - n) + n
      }
      function w(t, n, r) {
        var e = b(t, n, r)
        return e <= 0 ? 0 : e
      }
      function x(t, n, r) {
        return 0 === t
          ? n
          : 1 === t
          ? r
          : { x: b(t, n.x, r.x), y: b(t, n.y, r.y) }
      }
      function k(t, n, r) {
        var e = (function (t, n, r) {
          return Math.round(b(t, n, r))
        })(t, n, r)
        return e <= 0 ? 0 : e >= 255 ? 255 : e
      }
      function A(t, n, r) {
        return 0 === t
          ? n
          : 1 === t
          ? r
          : {
              r: k(t, n.r, r.r),
              g: k(t, n.g, r.g),
              b: k(t, n.b, r.b),
              a: b(t, null == n.a ? 1 : n.a, null == r.a ? 1 : r.a),
            }
      }
      function _(t, n, r) {
        if (0 === t) return n
        if (1 === t) return r
        var e = n.length
        if (e !== r.length) return m(t, n, r)
        for (var i = [], o = 0; o < e; o++) i.push(A(t, n[o], r[o]))
        return i
      }
      function S(t, n) {
        for (var r = [], e = 0; e < t; e++) r.push(n)
        return r
      }
      function O(t, n) {
        if (--n <= 0) return t
        var r = (t = Object.assign([], t)).length
        do {
          for (var e = 0; e < r; e++) t.push(t[e])
        } while (--n > 0)
        return t
      }
      var M,
        j = (function () {
          function t(r) {
            n(this, t), (this.list = r), (this.length = r.length)
          }
          return (
            e(t, [
              {
                key: 'setAttribute',
                value: function (t, n) {
                  for (var r = this.list, e = 0; e < this.length; e++)
                    r[e].setAttribute(t, n)
                },
              },
              {
                key: 'removeAttribute',
                value: function (t) {
                  for (var n = this.list, r = 0; r < this.length; r++)
                    n[r].removeAttribute(t)
                },
              },
              {
                key: 'style',
                value: function (t, n) {
                  for (var r = this.list, e = 0; e < this.length; e++)
                    r[e].style[t] = n
                },
              },
            ]),
            t
          )
        })(),
        F = /-./g,
        P = function (t, n) {
          return n.toUpperCase()
        }
      function B(t) {
        return 'function' == typeof t ? t : m
      }
      function I(t) {
        return t
          ? 'function' == typeof t
            ? t
            : Array.isArray(t)
            ? (function (t) {
                var n =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : s
                if (!Array.isArray(t)) return n
                switch (t.length) {
                  case 1:
                    return d(t[0]) || n
                  case 2:
                    return d(t[0], t[1]) || n
                  case 4:
                    return h(t[0], t[1], t[2], t[3]) || n
                }
                return n
              })(t, null)
            : (function (t, n) {
                var r =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : s
                switch (t) {
                  case 'linear':
                    return s
                  case 'steps':
                    return d(n.steps || 1, n.jump || 0) || r
                  case 'bezier':
                  case 'cubic-bezier':
                    return h(n.x1 || 0, n.y1 || 0, n.x2 || 0, n.y2 || 0) || r
                }
                return r
              })(t.type, t.value, null)
          : null
      }
      function R(t, n, r) {
        var e = arguments.length > 3 && void 0 !== arguments[3] && arguments[3],
          i = n.length - 1
        if (t <= n[0].t) return e ? [0, 0, n[0].v] : n[0].v
        if (t >= n[i].t) return e ? [i, 1, n[i].v] : n[i].v
        var o,
          u = n[0],
          a = null
        for (o = 1; o <= i; o++) {
          if (!(t > n[o].t)) {
            a = n[o]
            break
          }
          u = n[o]
        }
        return null == a
          ? e
            ? [i, 1, n[i].v]
            : n[i].v
          : u.t === a.t
          ? e
            ? [o, 1, a.v]
            : a.v
          : ((t = (t - u.t) / (a.t - u.t)),
            u.e && (t = u.e(t)),
            e ? [o, t, r(t, u.v, a.v)] : r(t, u.v, a.v))
      }
      function q(t, n) {
        var r =
          arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null
        return t && t.length
          ? 'function' != typeof n
            ? null
            : ('function' != typeof r && (r = null),
              function (e) {
                var i = R(e, t, n)
                return null != i && r && (i = r(i)), i
              })
          : null
      }
      function E(t, n) {
        return t.t - n.t
      }
      function T(n, r, e, i, o) {
        var u,
          a = '@' === e[0],
          l = '#' === e[0],
          f = M[e],
          s = m
        switch (
          (a
            ? ((u = e.substr(1)), (e = u.replace(F, P)))
            : l && (e = e.substr(1)),
          t(f))
        ) {
          case 'function':
            if (((s = f(i, o, R, I, e, a, r, n)), l)) return s
            break
          case 'string':
            s = q(i, B(f))
            break
          case 'object':
            if ((s = q(i, B(f.i), f.f)) && 'function' == typeof f.u)
              return f.u(r, s, e, a, n)
        }
        return s
          ? (function (t, n, r) {
              var e =
                arguments.length > 3 && void 0 !== arguments[3] && arguments[3]
              if (e)
                return t instanceof j
                  ? function (e) {
                      return t.style(n, r(e))
                    }
                  : function (e) {
                      return (t.style[n] = r(e))
                    }
              if (Array.isArray(n)) {
                var i = n.length
                return function (e) {
                  var o = r(e)
                  if (null == o)
                    for (var u = 0; u < i; u++) t[u].removeAttribute(n)
                  else for (var a = 0; a < i; a++) t[a].setAttribute(n, o)
                }
              }
              return function (e) {
                var i = r(e)
                null == i ? t.removeAttribute(n) : t.setAttribute(n, i)
              }
            })(r, e, s, a)
          : null
      }
      function N(n, r, e, i) {
        if (!i || 'object' !== t(i)) return null
        var o = null,
          u = null
        return (
          Array.isArray(i)
            ? (u = (function (t) {
                if (!t || !t.length) return null
                for (var n = 0; n < t.length; n++)
                  t[n].e && (t[n].e = I(t[n].e))
                return t.sort(E)
              })(i))
            : ((u = i.keys), (o = i.data || null)),
          u ? T(n, r, e, u, o) : null
        )
      }
      function C(t, n, r) {
        if (!r) return null
        var e = []
        for (var i in r)
          if (r.hasOwnProperty(i)) {
            var o = N(t, n, i, r[i])
            o && e.push(o)
          }
        return e.length ? e : null
      }
      function z(t, n) {
        if (!n.duration || n.duration < 0) return null
        var r = (function (t, n) {
          if (!n) return null
          var r = []
          if (Array.isArray(n))
            for (var e = n.length, i = 0; i < e; i++) {
              var o = n[i]
              if (2 === o.length) {
                var u = null
                if ('string' == typeof o[0]) u = t.getElementById(o[0])
                else if (Array.isArray(o[0])) {
                  u = []
                  for (var a = 0; a < o[0].length; a++)
                    if ('string' == typeof o[0][a]) {
                      var l = t.getElementById(o[0][a])
                      l && u.push(l)
                    }
                  u = u.length ? (1 === u.length ? u[0] : new j(u)) : null
                }
                if (u) {
                  var f = C(t, u, o[1])
                  f && (r = r.concat(f))
                }
              }
            }
          else
            for (var s in n)
              if (n.hasOwnProperty(s)) {
                var c = t.getElementById(s)
                if (c) {
                  var h = C(t, c, n[s])
                  h && (r = r.concat(h))
                }
              }
          return r.length ? r : null
        })(t, n.elements)
        return r
          ? (function (t, n) {
              var r =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : 1 / 0,
                e =
                  arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : 1,
                i =
                  arguments.length > 4 &&
                  void 0 !== arguments[4] &&
                  arguments[4],
                o =
                  arguments.length > 5 && void 0 !== arguments[5]
                    ? arguments[5]
                    : 1,
                u = t.length,
                a = e > 0 ? n : 0
              i && r % 2 == 0 && (a = n - a)
              var l = null
              return function (f, s) {
                var c = f % n,
                  h = 1 + (f - c) / n
                ;(s *= e), i && h % 2 == 0 && (s = -s)
                var v = !1
                if (h > r) (c = a), (v = !0), -1 === o && (c = e > 0 ? 0 : n)
                else if ((s < 0 && (c = n - c), c === l)) return !1
                l = c
                for (var y = 0; y < u; y++) t[y](c)
                return v
              }
            })(
              r,
              n.duration,
              n.iterations || 1 / 0,
              n.direction || 1,
              !!n.alternate,
              n.fill || 1
            )
          : null
      }
      var L = (function () {
        function t(r, e) {
          var i =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {}
          n(this, t),
            (this._id = 0),
            (this._running = !1),
            (this._rollingBack = !1),
            (this._animations = r),
            (this.duration = e.duration),
            (this.alternate = e.alternate),
            (this.fill = e.fill),
            (this.iterations = e.iterations),
            (this.direction = i.direction || 1),
            (this.speed = i.speed || 1),
            (this.fps = i.fps || 100),
            (this.offset = i.offset || 0),
            (this.rollbackStartOffset = 0)
        }
        return (
          e(
            t,
            [
              {
                key: '_rollback',
                value: function () {
                  var t = this,
                    n = 1 / 0,
                    r = null
                  ;(this.rollbackStartOffset = this.offset),
                    this._rollingBack ||
                      ((this._rollingBack = !0), (this._running = !0))
                  this._id = window.requestAnimationFrame(function e(i) {
                    if (t._rollingBack) {
                      null == r && (r = i)
                      var o = i - r,
                        u = t.rollbackStartOffset - o,
                        a = Math.round(u * t.speed)
                      if (a > t.duration && n != 1 / 0) {
                        var l = !!t.alternate && (a / t.duration) % 2 > 1,
                          f = a % t.duration
                        a = (f += l ? t.duration : 0) || t.duration
                      }
                      var s = t.fps ? 1e3 / t.fps : 0,
                        c = Math.max(0, a)
                      if (c < n - s) {
                        ;(t.offset = c), (n = c)
                        for (
                          var h = t._animations, v = h.length, y = 0;
                          y < v;
                          y++
                        )
                          h[y](c, t.direction)
                      }
                      var d = !1
                      if (t.iterations > 0 && -1 === t.fill) {
                        var g = t.iterations * t.duration,
                          p = g == a
                        ;(a = p ? 0 : a),
                          (t.offset = p ? 0 : t.offset),
                          (d = a > g)
                      }
                      a > 0 && t.offset >= a && !d
                        ? (t._id = window.requestAnimationFrame(e))
                        : t.stop()
                    }
                  })
                },
              },
              {
                key: '_start',
                value: function () {
                  var t = this,
                    n =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : 0,
                    r = -1 / 0,
                    e = null,
                    i = {},
                    o = function o(u) {
                      ;(t._running = !0), null == e && (e = u)
                      var a = Math.round((u - e + n) * t.speed),
                        l = t.fps ? 1e3 / t.fps : 0
                      if (a > r + l && !t._rollingBack) {
                        ;(t.offset = a), (r = a)
                        for (
                          var f = t._animations, s = f.length, c = 0, h = 0;
                          h < s;
                          h++
                        )
                          i[h]
                            ? c++
                            : ((i[h] = f[h](a, t.direction)), i[h] && c++)
                        if (c === s) return void t._stop()
                      }
                      t._id = window.requestAnimationFrame(o)
                    }
                  this._id = window.requestAnimationFrame(o)
                },
              },
              {
                key: '_stop',
                value: function () {
                  this._id && window.cancelAnimationFrame(this._id),
                    (this._running = !1),
                    (this._rollingBack = !1)
                },
              },
              {
                key: 'play',
                value: function () {
                  ;(!this._rollingBack && this._running) ||
                    ((this._rollingBack = !1),
                    this.rollbackStartOffset > this.duration &&
                      ((this.offset =
                        this.rollbackStartOffset -
                        ((this.rollbackStartOffset - this.offset) %
                          this.duration)),
                      (this.rollbackStartOffset = 0)),
                    this._start(this.offset))
                },
              },
              {
                key: 'stop',
                value: function () {
                  this._stop(),
                    (this.offset = 0),
                    (this.rollbackStartOffset = 0)
                  var t = this.direction,
                    n = this._animations
                  window.requestAnimationFrame(function () {
                    for (var r = 0; r < n.length; r++) n[r](0, t)
                  })
                },
              },
              {
                key: 'reachedToEnd',
                value: function () {
                  return (
                    this.iterations > 0 &&
                    this.offset >= this.iterations * this.duration
                  )
                },
              },
              {
                key: 'restart',
                value: function () {
                  this._stop(), (this.offset = 0), this._start()
                },
              },
              {
                key: 'pause',
                value: function () {
                  this._stop()
                },
              },
              {
                key: 'reverse',
                value: function () {
                  this.direction = -this.direction
                },
              },
            ],
            [
              {
                key: 'build',
                value: function (n, r) {
                  return (n = (function (t, n) {
                    if (
                      ((M = n), !t || !t.root || !Array.isArray(t.animations))
                    )
                      return null
                    for (
                      var r = document.getElementsByTagName('svg'),
                        e = !1,
                        i = 0;
                      i < r.length;
                      i++
                    )
                      if (r[i].id === t.root && !r[i].svgatorAnimation) {
                        ;(e = r[i]).svgatorAnimation = !0
                        break
                      }
                    if (!e) return null
                    var o = t.animations
                      .map(function (t) {
                        return z(e, t)
                      })
                      .filter(function (t) {
                        return !!t
                      })
                    return o.length
                      ? {
                          element: e,
                          animations: o,
                          animationSettings: t.animationSettings,
                          options: t.options || void 0,
                        }
                      : null
                  })(n, r))
                    ? {
                        el: n.element,
                        options: n.options || {},
                        player: new t(
                          n.animations,
                          n.animationSettings,
                          n.options
                        ),
                      }
                    : null
                },
              },
            ]
          ),
          t
        )
      })()
      !(function () {
        for (
          var t = 0, n = ['ms', 'moz', 'webkit', 'o'], r = 0;
          r < n.length && !window.requestAnimationFrame;
          ++r
        )
          (window.requestAnimationFrame =
            window[n[r] + 'RequestAnimationFrame']),
            (window.cancelAnimationFrame =
              window[n[r] + 'CancelAnimationFrame'] ||
              window[n[r] + 'CancelRequestAnimationFrame'])
        window.requestAnimationFrame ||
          ((window.requestAnimationFrame = function (n) {
            var r = Date.now(),
              e = Math.max(0, 16 - (r - t)),
              i = window.setTimeout(function () {
                n(r + e)
              }, e)
            return (t = r + e), i
          }),
          (window.cancelAnimationFrame = window.clearTimeout))
      })()
      var D = /\.0+$/g
      function Q(t) {
        return Number.isInteger(t) ? t + '' : t.toFixed(6).replace(D, '')
      }
      function U(t) {
        var n =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : ' '
        return t && t.length ? t.map(Q).join(n) : ''
      }
      function V(t) {
        return t
          ? null == t.a || t.a >= 1
            ? 'rgb(' + t.r + ',' + t.g + ',' + t.b + ')'
            : 'rgba(' + t.r + ',' + t.g + ',' + t.b + ',' + t.a + ')'
          : 'transparent'
      }
      var $ = {
          f: null,
          i: function (t, n, r) {
            return 0 === t
              ? n
              : 1 === t
              ? r
              : { x: w(t, n.x, r.x), y: w(t, n.y, r.y) }
          },
          u: function (t, n) {
            return function (r) {
              var e = n(r)
              t.setAttribute('rx', Q(e.x)), t.setAttribute('ry', Q(e.y))
            }
          },
        },
        G = {
          f: null,
          i: function (t, n, r) {
            return 0 === t
              ? n
              : 1 === t
              ? r
              : {
                  width: w(t, n.width, r.width),
                  height: w(t, n.height, r.height),
                }
          },
          u: function (t, n) {
            return function (r) {
              var e = n(r)
              t.setAttribute('width', Q(e.width)),
                t.setAttribute('height', Q(e.height))
            }
          },
        },
        H = Math.sin,
        Y = Math.cos,
        Z = Math.acos,
        J = Math.asin,
        K = Math.tan,
        W = Math.atan2,
        X = Math.PI / 180,
        tt = 180 / Math.PI,
        nt = Math.sqrt,
        rt = (function () {
          function t() {
            var r =
                arguments.length > 0 && void 0 !== arguments[0]
                  ? arguments[0]
                  : 1,
              e =
                arguments.length > 1 && void 0 !== arguments[1]
                  ? arguments[1]
                  : 0,
              i =
                arguments.length > 2 && void 0 !== arguments[2]
                  ? arguments[2]
                  : 0,
              o =
                arguments.length > 3 && void 0 !== arguments[3]
                  ? arguments[3]
                  : 1,
              u =
                arguments.length > 4 && void 0 !== arguments[4]
                  ? arguments[4]
                  : 0,
              a =
                arguments.length > 5 && void 0 !== arguments[5]
                  ? arguments[5]
                  : 0
            n(this, t),
              (this.m = [r, e, i, o, u, a]),
              (this.i = null),
              (this.w = null),
              (this.s = null)
          }
          return (
            e(t, [
              {
                key: 'determinant',
                get: function () {
                  var t = this.m
                  return t[0] * t[3] - t[1] * t[2]
                },
              },
              {
                key: 'isIdentity',
                get: function () {
                  if (null === this.i) {
                    var t = this.m
                    this.i =
                      1 === t[0] &&
                      0 === t[1] &&
                      0 === t[2] &&
                      1 === t[3] &&
                      0 === t[4] &&
                      0 === t[5]
                  }
                  return this.i
                },
              },
              {
                key: 'point',
                value: function (t, n) {
                  var r = this.m
                  return {
                    x: r[0] * t + r[2] * n + r[4],
                    y: r[1] * t + r[3] * n + r[5],
                  }
                },
              },
              {
                key: 'translateSelf',
                value: function () {
                  var t =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : 0,
                    n =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : 0
                  if (!t && !n) return this
                  var r = this.m
                  return (
                    (r[4] += r[0] * t + r[2] * n),
                    (r[5] += r[1] * t + r[3] * n),
                    (this.w = this.s = this.i = null),
                    this
                  )
                },
              },
              {
                key: 'rotateSelf',
                value: function () {
                  var t =
                    arguments.length > 0 && void 0 !== arguments[0]
                      ? arguments[0]
                      : 0
                  if ((t %= 360)) {
                    var n = H((t *= X)),
                      r = Y(t),
                      e = this.m,
                      i = e[0],
                      o = e[1]
                    ;(e[0] = i * r + e[2] * n),
                      (e[1] = o * r + e[3] * n),
                      (e[2] = e[2] * r - i * n),
                      (e[3] = e[3] * r - o * n),
                      (this.w = this.s = this.i = null)
                  }
                  return this
                },
              },
              {
                key: 'scaleSelf',
                value: function () {
                  var t =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : 1,
                    n =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : 1
                  if (1 !== t || 1 !== n) {
                    var r = this.m
                    ;(r[0] *= t),
                      (r[1] *= t),
                      (r[2] *= n),
                      (r[3] *= n),
                      (this.w = this.s = this.i = null)
                  }
                  return this
                },
              },
              {
                key: 'skewSelf',
                value: function (t, n) {
                  if (((n %= 360), (t %= 360) || n)) {
                    var r = this.m,
                      e = r[0],
                      i = r[1],
                      o = r[2],
                      u = r[3]
                    t && ((t = K(t * X)), (r[2] += e * t), (r[3] += i * t)),
                      n && ((n = K(n * X)), (r[0] += o * n), (r[1] += u * n)),
                      (this.w = this.s = this.i = null)
                  }
                  return this
                },
              },
              {
                key: 'resetSelf',
                value: function () {
                  var t =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : 1,
                    n =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : 0,
                    r =
                      arguments.length > 2 && void 0 !== arguments[2]
                        ? arguments[2]
                        : 0,
                    e =
                      arguments.length > 3 && void 0 !== arguments[3]
                        ? arguments[3]
                        : 1,
                    i =
                      arguments.length > 4 && void 0 !== arguments[4]
                        ? arguments[4]
                        : 0,
                    o =
                      arguments.length > 5 && void 0 !== arguments[5]
                        ? arguments[5]
                        : 0,
                    u = this.m
                  return (
                    (u[0] = t),
                    (u[1] = n),
                    (u[2] = r),
                    (u[3] = e),
                    (u[4] = i),
                    (u[5] = o),
                    (this.w = this.s = this.i = null),
                    this
                  )
                },
              },
              {
                key: 'recomposeSelf',
                value: function () {
                  var t =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : null,
                    n =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : null,
                    r =
                      arguments.length > 2 && void 0 !== arguments[2]
                        ? arguments[2]
                        : null,
                    e =
                      arguments.length > 3 && void 0 !== arguments[3]
                        ? arguments[3]
                        : null,
                    i =
                      arguments.length > 4 && void 0 !== arguments[4]
                        ? arguments[4]
                        : null
                  return (
                    this.isIdentity || this.resetSelf(),
                    t && (t.x || t.y) && this.translateSelf(t.x, t.y),
                    n && this.rotateSelf(n),
                    r &&
                      (r.x && this.skewSelf(r.x, 0),
                      r.y && this.skewSelf(0, r.y)),
                    !e || (1 === e.x && 1 === e.y) || this.scaleSelf(e.x, e.y),
                    i && (i.x || i.y) && this.translateSelf(i.x, i.y),
                    this
                  )
                },
              },
              {
                key: 'decompose',
                value: function () {
                  var t =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : 0,
                    n =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : 0,
                    r = this.m,
                    e = r[0] * r[0] + r[1] * r[1],
                    i = [
                      [r[0], r[1]],
                      [r[2], r[3]],
                    ],
                    o = nt(e)
                  if (0 === o)
                    return {
                      origin: { x: r[4], y: r[5] },
                      translate: { x: t, y: n },
                      scale: { x: 0, y: 0 },
                      skew: { x: 0, y: 0 },
                      rotate: 0,
                    }
                  ;(i[0][0] /= o), (i[0][1] /= o)
                  var u = r[0] * r[3] - r[1] * r[2] < 0
                  u && (o = -o)
                  var a = i[0][0] * i[1][0] + i[0][1] * i[1][1]
                  ;(i[1][0] -= i[0][0] * a), (i[1][1] -= i[0][1] * a)
                  var l = nt(i[1][0] * i[1][0] + i[1][1] * i[1][1])
                  if (0 === l)
                    return {
                      origin: { x: r[4], y: r[5] },
                      translate: { x: t, y: n },
                      scale: { x: o, y: 0 },
                      skew: { x: 0, y: 0 },
                      rotate: 0,
                    }
                  ;(i[1][0] /= l), (i[1][1] /= l), (a /= l)
                  var f = 0
                  return (
                    i[1][1] < 0
                      ? ((f = Z(i[1][1]) * tt), i[0][1] < 0 && (f = 360 - f))
                      : (f = J(i[0][1]) * tt),
                    u && (f = -f),
                    (a = W(a, nt(i[0][0] * i[0][0] + i[0][1] * i[0][1])) * tt),
                    u && (a = -a),
                    {
                      origin: { x: r[4], y: r[5] },
                      translate: { x: t, y: n },
                      scale: { x: o, y: l },
                      skew: { x: a, y: 0 },
                      rotate: f,
                    }
                  )
                },
              },
              {
                key: 'toString',
                value: function () {
                  return (
                    null === this.s &&
                      (this.s = 'matrix(' + this.m.map(it).join(' ') + ')'),
                    this.s
                  )
                },
              },
            ]),
            t
          )
        })(),
        et = /\.0+$/
      function it(t) {
        return Number.isInteger(t) ? t : t.toFixed(14).replace(et, '')
      }
      function ot(t, n, r) {
        return t + (n - t) * r
      }
      function ut(t, n, r) {
        var e = arguments.length > 3 && void 0 !== arguments[3] && arguments[3],
          i = { x: ot(t.x, n.x, r), y: ot(t.y, n.y, r) }
        return e && (i.a = at(t, n)), i
      }
      function at(t, n) {
        return Math.atan2(n.y - t.y, n.x - t.x)
      }
      Object.freeze({
        M: 2,
        L: 2,
        Z: 0,
        H: 1,
        V: 1,
        C: 6,
        Q: 4,
        T: 2,
        S: 4,
        A: 7,
      })
      var lt = {},
        ft = null
      function st(n) {
        var r = (function () {
          if (ft) return ft
          if (
            'object' !==
            ('undefined' == typeof document ? 'undefined' : t(document))
          )
            return {}
          var n = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
          return (
            (n.style.position = 'absolute'),
            (n.style.opacity = '0.01'),
            (n.style.zIndex = '-9999'),
            (n.style.left = '-9999px'),
            (n.style.width = '1px'),
            (n.style.height = '1px'),
            (ft = { svg: n })
          )
        })().svg
        if (!r)
          return function (t) {
            return null
          }
        var e = document.createElementNS(r.namespaceURI, 'path')
        e.setAttributeNS(null, 'd', n),
          e.setAttributeNS(null, 'fill', 'none'),
          e.setAttributeNS(null, 'stroke', 'none'),
          r.appendChild(e)
        var i = e.getTotalLength()
        return function (t) {
          var n = e.getPointAtLength(i * t)
          return { x: n.x, y: n.y }
        }
      }
      function ct(t) {
        return lt[t] ? lt[t] : (lt[t] = st(t))
      }
      function ht(t, n, r, e) {
        if (!t || !e) return !1
        var i = ['M', t.x, t.y]
        if (
          (n &&
            r &&
            (i.push('C'), i.push(n.x), i.push(n.y), i.push(r.x), i.push(r.y)),
          n ? !r : r)
        ) {
          var o = n || r
          i.push('Q'), i.push(o.x), i.push(o.y)
        }
        return n || r || i.push('L'), i.push(e.x), i.push(e.y), i.join(' ')
      }
      function vt(t, n, r, e) {
        var i =
            arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : 1,
          o = ht(t, n, r, e),
          u = ct(o)
        try {
          return u(i)
        } catch (t) {
          return null
        }
      }
      function yt(t, n, r, e) {
        var i = 1 - e
        return i * i * t + 2 * i * e * n + e * e * r
      }
      function dt(t, n, r, e) {
        return 2 * (1 - e) * (n - t) + 2 * e * (r - n)
      }
      function gt(t, n, r, e) {
        var i = arguments.length > 4 && void 0 !== arguments[4] && arguments[4],
          o = vt(t, n, null, r, e)
        return (
          o || (o = { x: yt(t.x, n.x, r.x, e), y: yt(t.y, n.y, r.y, e) }),
          i && (o.a = pt(t, n, r, e)),
          o
        )
      }
      function pt(t, n, r, e) {
        return Math.atan2(dt(t.y, n.y, r.y, e), dt(t.x, n.x, r.x, e))
      }
      function mt(t, n, r, e, i) {
        var o = i * i
        return (
          i * o * (e - t + 3 * (n - r)) +
          3 * o * (t + r - 2 * n) +
          3 * i * (n - t) +
          t
        )
      }
      function bt(t, n, r, e, i) {
        var o = 1 - i
        return 3 * (o * o * (n - t) + 2 * o * i * (r - n) + i * i * (e - r))
      }
      function wt(t, n, r, e, i) {
        var o = arguments.length > 5 && void 0 !== arguments[5] && arguments[5],
          u = vt(t, n, r, e, i)
        return (
          u ||
            (u = {
              x: mt(t.x, n.x, r.x, e.x, i),
              y: mt(t.y, n.y, r.y, e.y, i),
            }),
          o && (u.a = xt(t, n, r, e, i)),
          u
        )
      }
      function xt(t, n, r, e, i) {
        return Math.atan2(bt(t.y, n.y, r.y, e.y, i), bt(t.x, n.x, r.x, e.x, i))
      }
      function kt(t, n, r) {
        var e = arguments.length > 3 && void 0 !== arguments[3] && arguments[3]
        if (_t(n)) {
          if (St(r)) return gt(n, r.start, r, t, e)
        } else if (_t(r)) {
          if (n.end) return gt(n, n.end, r, t, e)
        } else {
          if (n.end)
            return r.start
              ? wt(n, n.end, r.start, r, t, e)
              : gt(n, n.end, r, t, e)
          if (r.start) return gt(n, r.start, r, t, e)
        }
        return ut(n, r, t, e)
      }
      function At(t, n, r) {
        var e = kt(t, n, r, !0)
        return (
          (e.a =
            (function (t) {
              var n =
                arguments.length > 1 && void 0 !== arguments[1] && arguments[1]
              return n ? t + Math.PI : t
            })(e.a) / p),
          e
        )
      }
      function _t(t) {
        return !t.type || 'corner' === t.type
      }
      function St(t) {
        return null != t.start && !_t(t)
      }
      var Ot = new rt()
      var Mt = { f: Q, i: b },
        jt = {
          f: Q,
          i: function (t, n, r) {
            var e = b(t, n, r)
            return e <= 0 ? 0 : e >= 1 ? 1 : e
          },
        }
      function Ft(t, n, r, e, i, o, u, a) {
        return (
          (n = (function (t, n, r) {
            for (var e, i, o, u = t.length - 1, a = {}, l = 0; l <= u; l++)
              (e = t[l]).e && (e.e = n(e.e)),
                e.v &&
                  'g' === (i = e.v).t &&
                  i.r &&
                  (o = r.getElementById(i.r)) &&
                  (a[i.r] = o.querySelectorAll('stop'))
            return a
          })(t, e, a)),
          function (e) {
            var i,
              o = r(e, t, Pt)
            return o
              ? 'c' === o.t
                ? V(o.v)
                : 'g' === o.t
                ? (n[o.r] &&
                    (function (t, n) {
                      for (var r = 0, e = t.length; r < e; r++)
                        t[r].setAttribute('stop-color', V(n[r]))
                    })(n[o.r], o.v),
                  (i = o.r) ? 'url(#' + i + ')' : 'none')
                : 'none'
              : 'none'
          }
        )
      }
      function Pt(t, n, r) {
        if (0 === t) return n
        if (1 === t) return r
        if (n && r) {
          var e = n.t
          if (e === r.t)
            switch (n.t) {
              case 'c':
                return { t: e, v: A(t, n.v, r.v) }
              case 'g':
                if (n.r === r.r) return { t: e, v: _(t, n.v, r.v), r: n.r }
            }
        }
        return m(t, n, r)
      }
      var Bt = {
        fill: Ft,
        'fill-opacity': jt,
        stroke: Ft,
        'stroke-opacity': jt,
        'stroke-width': Mt,
        'stroke-dashoffset': { f: Q, i: b },
        'stroke-dasharray': {
          f: function (t) {
            var n =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : ' '
            return (
              t &&
                t.length > 0 &&
                (t = t.map(function (t) {
                  return Math.floor(1e4 * t) / 1e4
                })),
              U(t, n)
            )
          },
          i: function (t, n, r) {
            var e,
              i,
              o,
              u = n.length,
              a = r.length
            if (u !== a)
              if (0 === u) n = S((u = a), 0)
              else if (0 === a) (a = u), (r = S(u, 0))
              else {
                var l =
                  (o =
                    ((e = u) * (i = a)) /
                    (function (t, n) {
                      for (var r; n; ) (r = n), (n = t % n), (t = r)
                      return t || 1
                    })(e, i)) < 0
                    ? -o
                    : o
                ;(n = O(n, Math.floor(l / u))),
                  (r = O(r, Math.floor(l / a))),
                  (u = a = l)
              }
            for (var f = [], s = 0; s < u; s++) f.push(g(w(t, n[s], r[s]), 6))
            return f
          },
        },
        opacity: jt,
        transform: function (n, r, e, i) {
          if (
            !(n = (function (n, r) {
              if (!n || 'object' !== t(n)) return null
              var e = !1
              for (var i in n)
                n.hasOwnProperty(i) &&
                  (n[i] && n[i].length
                    ? (n[i].forEach(function (t) {
                        t.e && (t.e = r(t.e))
                      }),
                      (e = !0))
                    : delete n[i])
              return e ? n : null
            })(n, i))
          )
            return null
          var o = function (t, i, o) {
            var u =
              arguments.length > 3 && void 0 !== arguments[3]
                ? arguments[3]
                : null
            return n[t] ? e(i, n[t], o) : r && r[t] ? r[t] : u
          }
          return r && r.a && n.o
            ? function (t) {
                var r = e(t, n.o, At)
                return Ot.recomposeSelf(
                  r,
                  o('r', t, b, 0) + r.a,
                  o('k', t, x),
                  o('s', t, x),
                  o('t', t, x)
                ).toString()
              }
            : function (t) {
                return Ot.recomposeSelf(
                  o('o', t, kt, null),
                  o('r', t, b, 0),
                  o('k', t, x),
                  o('s', t, x),
                  o('t', t, x)
                ).toString()
              }
        },
        r: Mt,
        '#size': G,
        '#radius': $,
        _: function (t, n) {
          if (Array.isArray(t))
            for (var r = 0; r < t.length; r++) this[t[r]] = n
          else this[t] = n
        },
      }
      return (function (t) {
        !(function (t, n) {
          if ('function' != typeof n && null !== n)
            throw new TypeError(
              'Super expression must either be null or a function'
            )
          ;(t.prototype = Object.create(n && n.prototype, {
            constructor: { value: t, writable: !0, configurable: !0 },
          })),
            n && o(t, n)
        })(u, t)
        var r = a(u)
        function u() {
          return n(this, u), r.apply(this, arguments)
        }
        return (
          e(u, null, [
            {
              key: 'build',
              value: function (t) {
                var n = l(i(u), 'build', this).call(this, t, Bt)
                n.el, n.options
                var r = n.player
                return (
                  (function (t, n, r) {
                    t.play()
                  })(r),
                  r
                )
              },
            },
          ]),
          u
        )
      })(L)
    })
    __SVGATOR_PLAYER__.build({
      root: 'eWYhCtNA7Py1',
      animations: [
        {
          duration: 5000,
          direction: 1,
          iterations: 0,
          fill: 1,
          alternate: false,
          speed: 1,
          elements: {
            eWYhCtNA7Py2: {
              transform: {
                data: { t: { x: -250, y: -250 } },
                keys: {
                  o: [
                    { t: 1800, v: { x: 250, y: 250, type: 'corner' } },
                    { t: 3000, v: { x: 250, y: 220, type: 'corner' } },
                    { t: 3800, v: { x: 250, y: 250, type: 'corner' } },
                    { t: 4000, v: { x: 250, y: 250, type: 'corner' } },
                  ],
                  s: [
                    { t: 0, v: { x: 0.95, y: 0.95 } },
                    { t: 1000, v: { x: 1, y: 1 } },
                    { t: 1300, v: { x: 0.2, y: 0.2 } },
                    { t: 1500, v: { x: 0.25, y: 0.25 } },
                    { t: 3500, v: { x: 0.25, y: 0.25 } },
                    { t: 4000, v: { x: 0.9, y: 0.9 } },
                    { t: 4500, v: { x: 0.95, y: 0.95 } },
                  ],
                },
              },
              opacity: [
                { t: 0, v: 0.5 },
                { t: 500, v: 1 },
                { t: 1800, v: 1 },
                { t: 2500, v: 0 },
                { t: 4000, v: 0 },
                { t: 5000, v: 0.5 },
              ],
            },
            eWYhCtNA7Py3: {
              transform: {
                data: { t: { x: -250, y: -249.95 } },
                keys: {
                  o: [
                    { t: 0, v: { x: 250, y: 210, type: 'corner' } },
                    { t: 1500, v: { x: 250, y: 250, type: 'corner' } },
                    { t: 4300, v: { x: 250, y: 250, type: 'corner' } },
                    { t: 5000, v: { x: 250, y: 210, type: 'corner' } },
                  ],
                  s: [
                    { t: 0, v: { x: 0.25, y: 0.25 } },
                    { t: 1000, v: { x: 0.25, y: 0.25 } },
                    { t: 1500, v: { x: 0.9, y: 0.9 } },
                    { t: 2000, v: { x: 0.9, y: 0.9 } },
                    { t: 2500, v: { x: 0.9, y: 0.9 } },
                    { t: 3500, v: { x: 1, y: 1 } },
                    { t: 3800, v: { x: 0.2, y: 0.2 } },
                    { t: 4000, v: { x: 0.25, y: 0.25 } },
                    { t: 5000, v: { x: 0.25, y: 0.25 } },
                  ],
                },
              },
              opacity: [
                { t: 0, v: 0 },
                { t: 1500, v: 0 },
                { t: 2500, v: 0.5 },
                { t: 3000, v: 1 },
                { t: 4300, v: 1 },
                { t: 5000, v: 0 },
              ],
            },
            eWYhCtNA7Py4: {
              transform: {
                data: { t: { x: -220.39999389648438, y: -280.3999938964844 } },
                keys: {
                  o: [
                    {
                      t: 0,
                      v: {
                        x: 73.56999390000001,
                        y: 388.52499389999997,
                        type: 'corner',
                      },
                    },
                    {
                      t: 1000,
                      v: { x: 65.5999939, y: 394.4999939, type: 'corner' },
                    },
                    {
                      t: 1300,
                      v: {
                        x: 210.61999817,
                        y: 281.39999816999995,
                        type: 'corner',
                      },
                    },
                    {
                      t: 1500,
                      v: { x: 199.73333079, y: 290.29166412, type: 'corner' },
                    },
                    {
                      t: 2500,
                      v: {
                        x: 117.44999389999997,
                        y: 418.4149939,
                        type: 'corner',
                      },
                    },
                    {
                      t: 3500,
                      v: {
                        x: 105.49999389999995,
                        y: 434.3499939000001,
                        type: 'corner',
                      },
                    },
                    {
                      t: 3800,
                      v: {
                        x: 218.59999816999994,
                        y: 289.36999817000014,
                        type: 'corner',
                      },
                    },
                    {
                      t: 4000,
                      v: { x: 212.62499817, y: 297.33749817, type: 'corner' },
                    },
                  ],
                  s: [
                    { t: 1000, v: { x: 1, y: 1 } },
                    { t: 1300, v: { x: 0.3, y: 0.3 } },
                    { t: 2500, v: { x: 1, y: 1 } },
                    { t: 3500, v: { x: 1, y: 1 } },
                    { t: 3800, v: { x: 0.3, y: 0.3 } },
                  ],
                },
              },
              opacity: [
                { t: 500, v: 0 },
                { t: 1000, v: 1 },
                { t: 1300, v: 1 },
                { t: 1500, v: 0 },
                { t: 3000, v: 0 },
                { t: 3500, v: 1 },
                { t: 3800, v: 1 },
                { t: 4000, v: 0 },
              ],
            },
            eWYhCtNA7Py5: {
              transform: {
                data: { t: { x: -280.3999938964844, y: -220.39999389648438 } },
                keys: {
                  o: [
                    {
                      t: 0,
                      v: {
                        x: 426.42999389648435,
                        y: 111.47499389648438,
                        type: 'corner',
                      },
                    },
                    {
                      t: 1000,
                      v: {
                        x: 434.3999938964843,
                        y: 105.4999938964844,
                        type: 'corner',
                      },
                    },
                    {
                      t: 1300,
                      v: {
                        x: 289.3799981664843,
                        y: 218.59999816648437,
                        type: 'corner',
                      },
                    },
                    {
                      t: 1500,
                      v: {
                        x: 300.266664125003,
                        y: 209.708330786114,
                        type: 'corner',
                      },
                    },
                    {
                      t: 2500,
                      v: {
                        x: 382.54999389559543,
                        y: 81.58499389626216,
                        type: 'corner',
                      },
                    },
                    {
                      t: 3500,
                      v: {
                        x: 394.499993896188,
                        y: 65.64999389974359,
                        type: 'corner',
                      },
                    },
                    {
                      t: 3800,
                      v: {
                        x: 281.3999981663657,
                        y: 210.62999816778802,
                        type: 'corner',
                      },
                    },
                    {
                      t: 4000,
                      v: {
                        x: 287.37499816648443,
                        y: 202.66249816648437,
                        type: 'corner',
                      },
                    },
                  ],
                  s: [
                    { t: 1000, v: { x: 1, y: 1 } },
                    { t: 1300, v: { x: 0.3, y: 0.3 } },
                    { t: 2500, v: { x: 1, y: 1 } },
                    { t: 3500, v: { x: 1, y: 1 } },
                    { t: 3800, v: { x: 0.3, y: 0.3 } },
                  ],
                },
              },
              opacity: [
                { t: 500, v: 0 },
                { t: 1000, v: 1 },
                { t: 1300, v: 1 },
                { t: 1500, v: 0 },
                { t: 3000, v: 0 },
                { t: 3500, v: 1 },
                { t: 3800, v: 1 },
                { t: 4000, v: 0 },
              ],
            },
          },
        },
      ],
      options: {
        start: 'load',
        hover: 'freeze',
        click: 'freeze',
        scroll: 25,
        font: 'embed',
        exportedIds: 'unique',
        svgFormat: 'animated',
        title: 'test_2',
      },
      animationSettings: {
        duration: 5000,
        direction: 1,
        iterations: 0,
        fill: 1,
        alternate: false,
        speed: 1,
      },
    })
  },
}
</script>

<style></style>
