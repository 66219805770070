
import { defineComponent, ref, reactive } from 'vue'
import axios from 'axios'
import DemoForm from '@/components/DemoForm.vue'
import DemoResult from '@/components/DemoResult.vue'
import '@/static/svg/info.svg'
import '@/static/svg/arrow.svg'
import '@/static/svg/magnifier.svg'

export default defineComponent({
  name: 'Demo',
  components: {
    DemoForm,
    DemoResult,
  },

  setup() {
    const imageSrc = ref('')
    const imageUrl = ref('')
    const responseData = reactive({
      nsfw: 0,
      runtime1: 0,
      runtime2: 0,
      originalScale: '',
      originalSize: 88888,
      resaizerScale: '',
      resaizerSize: 0,
    })
    const isLoading = ref(false)
    const isSubmit = ref(false)
    const onSubmit = async (
      isRequestSent: boolean,
      reqData: {
        url: string
        size: number
        side: string
        token: string
      }
    ): Promise<void> => {
      if (isRequestSent) {
        isLoading.value = true
        await generateImage(reqData)
      } else {
        imageSrc.value = ''
        isSubmit.value = false
      }
    }

    async function generateImage(data: {
      url: string
      size: number
      side: string
      token: string
    }) {
      try {
        const response = await axios.get(
          'https://resaizerapi.codezero.tw/hash',
          {
            params: data,
          }
        )
        if (response.status !== 200 || !response.data.proxy_img_url) {
          throw new Error('generateImage: response_error')
        }
        await getImageInfo(response.data.proxy_img_url)
      } catch (e) {
        isLoading.value = false
        alert(e)
      }
    }

    async function getImageInfo(url: string) {
      try {
        const response = await axios({
          url,
          method: 'get',
          responseType: 'blob',
        })

        if (response.status !== 200) {
          throw new Error('getImageInfo: response_error')
        }

        const data = response.data
        const headers = response.headers

        responseData.nsfw = headers['rz-nsfw']
        responseData.runtime1 = headers['rz-elapsed-resize']
        responseData.runtime2 = headers['rz-elapsed-request']
        responseData.originalScale = headers['rz-original-dimensions']
        responseData.resaizerScale = headers['rz-dimensions']
        responseData.originalSize = headers['rz-original-content-length']
        responseData.resaizerSize = data.size
        imageSrc.value = window.URL.createObjectURL(data)
        imageUrl.value = url

        isSubmit.value = true
        isLoading.value = false
      } catch (error) {
        isLoading.value = false
        alert(error)
      }
    }

    return { isLoading, isSubmit, onSubmit, imageSrc, imageUrl, responseData }
  },
})
